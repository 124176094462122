import { typeTicketCode } from "../helpers/constants";
import { getModelTypeByCode } from "../helpers/utils";

export const depositMixin = {
  props: {
    deposit: {
      type: Object,
      required: true,
    },
  },

  computed: {
    canCreateTicket() {
      return this.deposit.status === "Echec";
    },
    activeClass() {
      switch (this.deposit.status) {
        case "Succès":
          return "success";
        case "Echec":
          return "danger";
        case "En cours":
          return "info";
      }
    },
    statusClassIcon() {
      switch (this.deposit.status) {
        case "Succès":
          return "fas fa-thumbs-up text-success";
        case "Echec":
          return "fas fa-thumbs-down text-danger";
        case "En cours":
          return "fas fa-hourglass-half text-info";
      }
    },
    operatorLabel() {
      return this.deposit.operator ? this.deposit.operator.label : "Correction";
    },
    operatorLogo() {
      return this.deposit.operator
        ? this.deposit.operator.logo
        : "https://api.be-wallet.net/storage/uploads/operators/BywqP1wPsxhw2WpctEiEnTyoXyyZIutxf5dhF5b2.png";
    },
    identifiant() {
      return this.deposit.phone ? this.deposit.phone : "Non spécifier";
    },
  },

  methods: {
    openTicketFromThis() {
      const query = {
        model_id: this.deposit.id,
        type: typeTicketCode.deposit,
        model_type: getModelTypeByCode(typeTicketCode.deposit),
        api_code: this.deposit.api_code,
        operator_id: this.deposit.operators_id,
      };
      this.$router.push({ name: "assistance", query: query });
    },
  },
};
